<template>
  <div class="medical-result">
    <i class="arrow"
       @click="$router.back(-1)"
       v-if="$route.query.applyid && $route.query.idcard"></i>
    <h2>测评结果</h2>
    <div class="content"
         v-for="(item,index) in resultArr"
         :key="index">
      <div class="banner">
        <h4>您的体质测试结果:</h4>
        <div class="r">
          {{item.result}} <span style="margin-left: 10px;">{{item.physique_name}}</span>
        </div>
      </div>
      <div class="result">
        <div class="tag">{{item.physique_name}}体质</div>
        <div class="item"
             v-for="(item, index) in JSON.parse(item.remark)"
             :key="'index'+ index">
          <span>{{item.key}}:</span>
          <p>{{item.value}}</p>
        </div>
      </div>
    </div>
    <div class="btn start"
         @click="goIndex()"
         v-if="$route.query.applyid && $route.query.idcard">返回首页</div>
    <div class="btn pause"
         @click="goRecord()">填写记录</div>
  </div>
</template>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: PingFangSC;
  color: rgba(103, 103, 103, 1);
  -webkit-text-size-adjust: none;
  background: rgba(249, 249, 251, 1);
}
.medical-result {
  width: 7.5rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 0.5rem;
  h2 {
    width: 100%;
    height: 1rem;
    background: rgba(0, 198, 174, 1);
    font-size: 0.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 1rem;
  }
  .content {
    width: 6.7rem;
    padding-bottom: 0.5rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.26rem;
    margin: 0.4rem auto 0;
    overflow: hidden;
    .banner {
      width: 100%;
      height: 2.37rem;
      background: url('../assets/img/medical/bg1.png');
      background-size: 100% 100%;
      padding: 0.4rem;
      color: #fff;
      text-align: left;
      h4 {
        font-size: 0.22rem;
        font-weight: 500;
      }
      .r {
        font-size: 0.58rem;
        font-weight: 500;
        line-height: 0.81rem;
        margin-top: 0.3rem;
      }
    }
    .result {
      padding: 0.4rem 0.35rem;
      text-align: left;
      .tag {
        font-size: 0.3rem;
        font-weight: 500;
        color: rgba(0, 198, 174, 1);
      }
      .item {
        display: flex;
        font-size: 0.22rem;
        margin-top: 0.5rem;
        span {
          font-weight: 500;
          color: rgba(69, 69, 69, 1);
        }
        p {
          flex: 1;
          font-weight: 400;
          color: rgba(103, 103, 103, 1);
          margin-left: 0.1rem;
        }
      }
    }
  }
  .btn {
    width: 2.96rem;
    height: 0.8rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0.02rem 0.17rem 0px rgba(238, 238, 238, 1);
    border-radius: 0.45rem;
    line-height: 0.8rem;
    margin: 0 auto;
    font-size: 0.36rem;
    font-weight: 500;
    color: rgba(0, 198, 174, 1);
    margin-top: 0.7rem;
    &.pause {
      color: rgba(214, 214, 214, 1);
    }
  }
}
</style>

<script>
import { apiUrl, http } from "../config";
export default {
  name: 'medicalresult',
  components: {
  },
  data () {
    return {
      resultArr: []
    }
  },
  mounted () {
    this.get();
  },
  methods: {
    goIndex () {
      let applyid = this.$route.query.applyid;
      let idcard = this.$route.query.idcard;
      this.$router.push({ 'path': '/medical', query: { applyid, idcard } })
    },
    get () {
      let id = this.$route.query.id;
      let applyid = this.$route.query.applyid;
      let idcard = this.$route.query.idcard;
      let url = '';
      if (!applyid) {
        url = `/cmpa/questionnaire/result/${id}`;
      } else {
        url = `/cmpa/questionnaire/result/${id}?applyid=${applyid}&idcard=${idcard}`;
      }
      http.get(url)
        .then((res) => {
          this.resultArr = res.data;
          console.log(this.resultArr)
          // let remark = data.remark;
          // remark = JSON.parse(remark);
          // this.remark = remark;
          // this.result = data.result;
          // this.physique_type = data.physique_type;
        });
    },
    goRecord () {
      let id = this.$route.query.id;
      this.$router.push({ 'path': '/medicalrecord', query: { id } })
    },
    back () {
      this.$router.go(-1);
    }
  }
}
</script>
